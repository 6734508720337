import {
    Box,
    Button,
    Card,
    Chip,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import EmailIcon from "@mui/icons-material/Email";
import Typography from "@mui/material/Typography";
import {
    DataGridPro,
    DataGridProProps,
    GridColumns,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import { orderBy } from "lodash";
import * as React from "react";
import { useContext, useMemo, useState } from "react";
import { CustomXylemAnalysisWithAnswer, DataContext } from "../contexts/DataContext";
import { MultipleSelectChip } from "../components/GenericChipSelect";
import { XYLEM_BLUE, iconsMapping } from "../utils/constants";
import { usePersistColumnSettings } from "../hooks/usePersistColumnSettings";
import { enqueueSnackbar } from "notistack";
import { AuthContext } from "../contexts/AuthContext";
import { patchAnswer } from "../utils/api";

export function DetailPanelContent({
    row: rowProp,
    noEdit,
}: {
    row: CustomXylemAnalysisWithAnswer;
    noEdit?: boolean;
}) {
    const [insatReason, setInsatReason] = React.useState(
        (rowProp.extendedResponse.agentAnalysis as any)?.insatReason || ""
    );

    const handleInsatReason = (event: SelectChangeEvent) => {
        setInsatReason(event.target.value as string);
    };

    const [insatTreatment, setInsatTreatment] = React.useState(
        (rowProp.extendedResponse.agentAnalysis as any)?.insatTreatment || ""
    );

    const handleInsatTreatment = (event: SelectChangeEvent) => {
        setInsatTreatment(event.target.value as string);
    };

    const [explanations, setExplanations] = React.useState(
        (rowProp.extendedResponse.agentAnalysis as any)?.explanations || ""
    );

    const handleExplanations = (event: SelectChangeEvent) => {
        setExplanations(event.target.value as string);
    };
    const { refreshXylemResponses } = useContext(DataContext);
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const calledBy = (rowProp.extendedResponse.agentAnalysis as any)?.user || "";
    const callDate = (
        (rowProp.extendedResponse.agentAnalysis as any)?.date || ""
    ).slice(0, 10);
    const alreadyContacted = Boolean(calledBy);

    const { jwt } = React.useContext(AuthContext);

    const submitReview = async () => {
        setLoadingSubmit(true);
        const result = await patchAnswer(
            jwt!,
            rowProp._id,
            insatReason,
            insatTreatment,
            explanations
        );
        if (!result.error) {
            //TODO: it would be better to refresh only the given row (...)
            await refreshXylemResponses();
            enqueueSnackbar("Successfully submitted review", { variant: "success" });
        } else {
            enqueueSnackbar("Error while submitting review", { variant: "error" });
        }
        setLoadingSubmit(false);
    };

    const coordinates = rowProp.answers.coordinates
        ? JSON.parse(rowProp.answers.coordinates)
        : {};

    return (
        <Box
            sx={{
                p: 4,
                backgroundColor: "#f9fbfa",
                borderBottom: "3px solid " + XYLEM_BLUE,
            }}
        >
            <Grid container spacing={3}>
                <Grid item md={6}>
                    {(rowProp.integration.XylemReference ||
                        rowProp.integration.CustomerReference) && (
                        <Stack direction="row" spacing={1}>
                            {rowProp.integration.XylemReference && (
                                <Chip
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    label={`Ref Xylem: ${rowProp.integration.XylemReference}`}
                                />
                            )}
                            {rowProp.integration.CustomerReference && (
                                <Chip
                                    color="success"
                                    variant="outlined"
                                    size="small"
                                    label={`Ref Customer: ${rowProp.integration.CustomerReference}`}
                                />
                            )}
                        </Stack>
                    )}

                    {(rowProp.integration.XylemReference ||
                        rowProp.integration.CustomerReference) && <br />}

                    <Stack direction="row" spacing={1}>
                        {
                            <Chip
                                color="primary"
                                variant="outlined"
                                size="small"
                                label={`Ref Answer Omni: ${rowProp._id}`}
                            />
                        }
                    </Stack>

                    <Box sx={{ py: 2 }}>
                        <Typography variant="subtitle2">Verbatim</Typography>
                        <Typography variant="body2" color="textSecondary">
                            {rowProp.answers.initialVerbatim}
                        </Typography>
                    </Box>

                    {rowProp.answers.improvementSuggestionsVerbatim && (
                        <Box sx={{ py: 2 }}>
                            <Typography variant="subtitle2">
                                Improvement suggestions verbatim
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {rowProp.answers.improvementSuggestionsVerbatim}
                            </Typography>
                        </Box>
                    )}
                    <br />
                    <br />
                    {!noEdit && (
                        <Grid container spacing={2}>
                            {calledBy && (
                                <Grid item xs={12}>
                                    <Chip
                                        label={`Called by ${calledBy} (${callDate})`}
                                        color="success"
                                    />
                                </Grid>
                            )}
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                        Reason for dissatisfaction
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={insatReason}
                                        label="Insat reason"
                                        onChange={handleInsatReason}
                                        disabled={alreadyContacted || loadingSubmit}
                                    >
                                        <MenuItem value={"reactivity"}>
                                            Reactivity
                                        </MenuItem>
                                        <MenuItem value={"skills"}>Skills</MenuItem>
                                        <MenuItem value={"reachability"}>
                                            Reachability
                                        </MenuItem>
                                        <MenuItem value={"leadTime"}>
                                            Lead Time
                                        </MenuItem>
                                        <MenuItem value={"price"}>Price</MenuItem>
                                        <MenuItem value={"product"}>Product</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                        Impact of the call
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={insatTreatment}
                                        label="Insat treatment"
                                        onChange={handleInsatTreatment}
                                        disabled={alreadyContacted || loadingSubmit}
                                    >
                                        <MenuItem value={"problemSolved"}>
                                            Problem solved
                                        </MenuItem>
                                        <MenuItem value={"followUpToBeDone"}>
                                            Follow up to be done
                                        </MenuItem>
                                        <MenuItem value={"voiceMail"}>
                                            Voice mail
                                        </MenuItem>
                                        <MenuItem value={"other"}>Other</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Explanations..."
                                    multiline
                                    rows={4}
                                    placeholder="Explanations..."
                                    value={explanations}
                                    onChange={handleExplanations as any}
                                    fullWidth
                                    disabled={alreadyContacted || loadingSubmit}
                                />
                            </Grid>
                            <Grid item container xs={4} alignItems={"center"}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={submitReview}
                                    disabled={alreadyContacted || loadingSubmit}
                                >
                                    Submit review
                                </Button>
                                {loadingSubmit && (
                                    <CircularProgress
                                        sx={{ marginLeft: 2 }}
                                        size={30}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                {rowProp.answers.shouldContactBecauseOfInsatisfaction &&
                    rowProp.answers.coordinates && (
                        <Grid item md={2}>
                            <Card sx={{ p: 4 }}>
                                <Stack spacing={2}>
                                    <Typography variant="subtitle2">Contact</Typography>

                                    <Stack direction="row" spacing={1}>
                                        <ContactPageIcon color="primary" />
                                        <span>{coordinates.name ?? "..."}</span>
                                    </Stack>

                                    <Stack direction="row" spacing={1}>
                                        <PhoneIcon color="primary" />
                                        <span>{coordinates.phone ?? "..."}</span>
                                    </Stack>

                                    <Stack direction="row" spacing={1}>
                                        <EmailIcon color="primary" />
                                        <span>{coordinates.mail ?? "..."}</span>
                                    </Stack>
                                </Stack>
                            </Card>
                        </Grid>
                    )}
                <Grid item md={2}>
                    <Card sx={{ p: 4 }}>
                        <Stack spacing={2}>
                            <Typography variant="subtitle2">
                                Semantic analysis
                            </Typography>
                            {(rowProp.extendedResponse?.listOfTagObjects || []).map(
                                (x) => {
                                    const splitedName = x.name.split(".");
                                    const category = splitedName
                                        .slice(0, splitedName.length - 1)
                                        .join(".");
                                    const TagIcon =
                                        iconsMapping[
                                            x.name as keyof typeof iconsMapping
                                        ] ||
                                        iconsMapping[
                                            category as keyof typeof iconsMapping
                                        ] ||
                                        Box;
                                    return (
                                        <Stack key={x.name} direction="row" spacing={1}>
                                            <TagIcon color="primary" />
                                            <span>{x.label}</span>
                                        </Stack>
                                    );
                                }
                            )}
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}

const columns: GridColumns = [
    { field: "country", headerName: "Country" },
    { field: "touchpoint", headerName: "Touchpoint" },
    { field: "filename", headerName: "Campaign name", width: 300 },

    { field: "parsedDate", headerName: "Date" },
    {
        valueGetter: ({ row }) => {
            return row.answersCalculated.generalRating;
        },
        headerName: "Rating",
        field: "rating",
        type: "number",
    },
    { field: "contactKey", headerName: "Contact Key" },
    { field: "customerCompanyName", headerName: "Company name" },
    { field: "customerCompanyGroupName", headerName: "Group name" },
    {
        field: "shouldContactBecauseOfInsatisfaction",
        headerName: "Should contact?",
    },
    {
        field: "contacted",
        headerName: "Already contacted?",
    },
];

export const MyFollowUp = () => {
    const getDetailPanelContent = React.useCallback<
        NonNullable<DataGridProProps["getDetailPanelContent"]>
    >(({ row }) => <DetailPanelContent row={row} />, []);

    const getDetailPanelHeight = React.useCallback<
        NonNullable<DataGridProProps["getDetailPanelHeight"]>
    >(() => "auto" as const, []);

    const [companyGroupNames, setCompanyGroupNames] = useState<string[]>([]);
    const [companyNames, setCompanyNames] = useState<string[]>([]);
    const [alignment, setAlignment] = React.useState("urg");

    const { xylemResponses, allCompanyGroupNames, allCompanyNames } =
        useContext(DataContext);

    const allRows = useMemo(() => {
        return orderBy(
            xylemResponses.filter((r) => {
                return (
                    (!companyGroupNames.length ||
                        companyGroupNames.includes(
                            r.integration.CustomerCompanyGroupName
                        )) &&
                    (!companyNames.length ||
                        companyNames.includes(r.integration.CustomerCompanyName))
                );
            }),
            (x) => x.parsedDate,
            "desc"
        );
    }, [xylemResponses, companyNames, companyGroupNames]);

    const rows = useMemo(() => {
        if (alignment === "urg") {
            return allRows.filter(
                (x) =>
                    x.contacted === "no" &&
                    x.shouldContactBecauseOfInsatisfaction === "yes"
            );
        }
        if (alignment === "contact") {
            return allRows.filter(
                (x) =>
                    x.contacted === "no" &&
                    x.shouldContactBecauseOfInsatisfaction !== "yes" &&
                    x.answersCalculated.generalRating <= 2
            );
        }
        if (alignment === "contacted") {
            const contacted = allRows.filter((r) => r.contacted === "yes");
            return contacted;
        }
        return allRows;
    }, [allRows, alignment]);

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string
    ) => {
        setAlignment(newAlignment);
    };

    const apiRef = useGridApiRef();
    usePersistColumnSettings(apiRef, "customers-grid");

    const notSelectedStyle = { fontWeight: "500" };
    const selectedStyle = {
        fontWeight: "700",
        textDecoration: "underline",
        textUnderlineOffset: "3px",
    };

    return (
        <Box
            sx={{ px: 4, height: "calc(100vh - 70px)" }}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"stretch"}
            justifyContent={"stretch"}
        >
            <Box>
                <Stack direction="row" sx={{ py: 4 }} spacing={2}>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                        sx={{ flex: 1 }}
                    >
                        <ToggleButton
                            value="urg"
                            style={
                                alignment === "urg" ? selectedStyle : notSelectedStyle
                            }
                        >
                            URG - Cust. to contact
                        </ToggleButton>
                        <ToggleButton
                            value="contact"
                            style={
                                alignment === "contact"
                                    ? selectedStyle
                                    : notSelectedStyle
                            }
                        >
                            Cust. to contact
                        </ToggleButton>
                        <ToggleButton
                            value="contacted"
                            style={
                                alignment === "contacted"
                                    ? selectedStyle
                                    : notSelectedStyle
                            }
                        >
                            Cust. contacted
                        </ToggleButton>
                        <ToggleButton
                            value="all"
                            style={
                                alignment === "all" ? selectedStyle : notSelectedStyle
                            }
                        >
                            Display all
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <Box sx={{ width: "300px" }}>
                        <MultipleSelectChip
                            values={companyGroupNames}
                            onNewValues={(values: any) => setCompanyGroupNames(values)}
                            options={allCompanyGroupNames}
                            id="mood-company-group-name"
                            label="Group Name"
                            placeholder="Group Name"
                        />
                    </Box>
                    <Box sx={{ width: "300px" }}>
                        <MultipleSelectChip
                            values={companyNames}
                            onNewValues={(values: any) => setCompanyNames(values)}
                            options={allCompanyNames}
                            id="mood-company-name"
                            label="Company Name"
                            placeholder="Company Name"
                        />
                    </Box>
                </Stack>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
                <DataGridPro
                    apiRef={apiRef}
                    columns={columns}
                    rows={rows}
                    rowThreshold={0}
                    getDetailPanelHeight={getDetailPanelHeight}
                    getDetailPanelContent={getDetailPanelContent}
                />
            </Box>
        </Box>
    );
};
