import { Stack } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { useContext, useEffect, useReducer } from 'react';
import { MultipleSelectChip } from './GenericChipSelect';
import { PeriodPicker } from "../components/PeriodPicker";
import {
  allCountries,
  allMarkets,
  allRegions,
  allSubRegions,
} from '../utils/constants';
import { DataContext } from '../contexts/DataContext';

export interface DashboardFilter {
  regions: string[];
  subRegions: string[];
  countries: string[];
  markets: string[];
  companyGroupNames: string[];
  companyNames: string[];
  range: DateRange<Date>
}

type Props = { onChange: (state: DashboardFilter) => void }

export const DashboardFilters = ({ onChange }: Props) => {
  const [
    state,
    dispatch,
  ] = useReducer(
    (state: DashboardFilter, data: Partial<DashboardFilter>) => {
      return { ...state, ...data };
    },
    {
      regions: [] as string[],
      subRegions: [] as string[],
      countries: [] as string[],
      markets: [] as string[],
      companyNames: [] as string[],
      companyGroupNames: [] as string[],
      range: [null, null]
    } as DashboardFilter,
  );
  const setRegions = (regions: string[]) => {
    dispatch({ regions });
  };
  const setSubRegions = (subRegions: string[]) => {
    dispatch({ subRegions });
  };
  const setCountries = (countries: string[]) => {
    dispatch({ countries });
  };
  const setMarkets = (markets: string[]) => {
    dispatch({ markets });
  };
  const setCompanyGroupNames = (companyGroupNames: string[]) => {
    dispatch({ companyGroupNames });
  };
  const setCompanyNames = (companyNames: string[]) => {
    dispatch({ companyNames });
  };
  const setRange = (range: DateRange<Date>) => {
    dispatch({ range });
  };
  
  const {allCompanyNames, allCompanyGroupNames} = useContext(DataContext);

  useEffect(() => {
    onChange(state)
  }, [state, onChange])

  return (
    <Stack spacing={1}>
      <MultipleSelectChip
        values={state.regions}
        onNewValues={(values: any) => setRegions(values)}
        options={allRegions}
        id="mood-region"
        label="Region"
        placeholder="Region"
      />
      <MultipleSelectChip
        values={state.subRegions}
        onNewValues={(values: any) => setSubRegions(values)}
        options={allSubRegions}
        id="mood-subregion"
        label="Subregion"
        placeholder="Subregion"
      />
      <MultipleSelectChip
        values={state.countries}
        onNewValues={(values: any) => setCountries(values)}
        options={allCountries}
        id="mood-market"
        label="Country"
        placeholder="Country"
      />
      <MultipleSelectChip
        values={state.markets}
        onNewValues={(values: any) => setMarkets(values)}
        options={allMarkets}
        id="mood-market"
        label="Market"
        placeholder="Market"
      />
      <MultipleSelectChip
        values={state.companyGroupNames}
        onNewValues={(values: any) => setCompanyGroupNames(values)}
        options={allCompanyGroupNames}
        id="mood-company-group-name"
        label="Group Name"
        placeholder="Group Name"
      />
      <MultipleSelectChip
        values={state.companyNames}
        onNewValues={(values: any) => setCompanyNames(values)}
        options={allCompanyNames}
        id="mood-company-name"
        label="Company Name"
        placeholder="Company Name"
      />
      <PeriodPicker onChange={val => setRange(val)} />
    </Stack>
  );
};
