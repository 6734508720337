import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";

export const DashboardByCountry = () => {
  const { jwtPayload } = useContext(AuthContext);
  return (
    <iframe
      title='dashboard-by-country'
      style={{ width: '98vw', height: '90vh' }}
      src={jwtPayload?.countryDashboardUrl}
    ></iframe>
  );
};
