import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import { allTouchpoints } from '../utils/constants';

export function TouchpointMultipleSelectChip({onNewTouchpoints, touchpoints}:any) {
  return (
    <Grid container>
      <Grid item xs={3}></Grid>
      <Grid item xs={6}>
        <div>
          <Autocomplete
            multiple
            id="tags-standard"
            options={allTouchpoints}
            getOptionLabel={(option) => option}
            defaultValue={touchpoints}
            onChange={(e, newTouchpoints) => {
              onNewTouchpoints(newTouchpoints);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Select touchpoints to compare"
                placeholder="Touchpoint"
              />
            )}
          />
        </div>
      </Grid>
    </Grid>
  );
}
