import {
    AddShoppingCart,
    AttachMoney,
    PriceCheck,
    CurrencyExchange,
    CreditScore,
    FactCheck,
    ShoppingCartCheckout,
    SecurityUpdateGood,
    LocationOff,
    Dangerous,
    ThumbUp,
    Shield,
    HourglassBottom,
    Abc,
    BookmarkAdded,
    Cancel,
    CompareArrows,
    PermPhoneMsg,
    TagFaces,
    VolunteerActivism,
    AddReaction,
    AlarmOn,
    Mood,
    SentimentVeryDissatisfied,
} from "@mui/icons-material";

export const allRegions = ["Europe"];
export const allSubRegions = ["South Europe", "North Europe"];
export const allCountries = ["FR", "UK", "DE", "IT", "ES", "PT"];
export const allMarkets = ["***", "CBS", "IND", "MUN"];

//This is the key of the localStorage item that contains the JWT token
export const JWT_TOKEN_LOCAL_STORAGE_KEY = "xylemJwtToken";

//TODO: verify international names and make stuff uniform!
export const allTouchpoints = [
    "Commissionning installation",
    "Customer Visit",
    "Delivery",
    "Product return",
    "Quotation",
    "Rental",
    "Repairing",
    "Warrantly claim",
    "Delivery date",
];

export const iconsMapping = {
    promotions: AddShoppingCart,
    promo: AddShoppingCart,
    price: AttachMoney,
    prix: AttachMoney,
    "value-for-money": PriceCheck,
    "price-increase": CurrencyExchange,
    "prix.var": CurrencyExchange,
    "return-allowance": CreditScore,
    choice: FactCheck,
    choix: FactCheck,
    "availability-stock": ShoppingCartCheckout,
    dispo: ShoppingCartCheckout,
    guarantee: SecurityUpdateGood,
    "missing-lost-product": LocationOff,
    "manque.produit": LocationOff,
    "damaged-product": Dangerous,
    abime: Dangerous,
    reliability: ThumbUp,
    fiabilite: ThumbUp,
    quality: Shield,
    "lead-time": HourglassBottom,
    delai: HourglassBottom,
    signature: Abc,
    "quality-of-service": BookmarkAdded,
    qualite: BookmarkAdded,
    service: BookmarkAdded,
    refusal: Cancel,
    "commercial-trade-off": CompareArrows,
    "geste.co": CompareArrows,
    "follow-up": PermPhoneMsg,
    reachability: PermPhoneMsg,
    joignabilite: PermPhoneMsg,
    "welcome-kindness": TagFaces,
    accueil: TagFaces,
    "attentiveness-and-availability": VolunteerActivism,
    ecoute: VolunteerActivism,
    skills: AddReaction,
    competence: AddReaction,
    "efficiency-reactivity": AlarmOn,
    efficacite: AlarmOn,
    feeling: Mood,
    ressenti: Mood,
    "feeling.negative": SentimentVeryDissatisfied,
};

export const XYLEM_BLUE = "#2685a6";
