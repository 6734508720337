import { jwtVerify, importSPKI } from "jose";
import { XylemJwtPayload } from "../types/types";

export function parseOrNull(raw: unknown) {
    if (!raw) return null;

    if (typeof raw === "string") {
        try {
            return JSON.parse(raw);
        } catch (e) {
            console.warn(`Failed to parse: ${raw.substring(0, 50)}`);
            return null;
        }
    }

    return null;
}

export function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getEnvironmentVariable(key: string) {
    return process.env[key] || "";
}

export function getAppVersion() {
    const appPackage = require("../../package.json");
    return getEnvironmentVariable("REACT_APP_VERSION") || appPackage.version;
}

export const verifyJwtP256SignatureAndGetPayloadOrNull = async (aJwt: string) => {
    const publicKey = await importSPKI(
        getEnvironmentVariable("REACT_APP_JWT_PUBLIC_KEY"),
        "ES256"
    );
    try {
        const result = await jwtVerify(aJwt, publicKey);
        return result.payload as XylemJwtPayload;
    } catch (e) {
        return null;
    }
};
