import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { AuthContext } from "./contexts/AuthContext";

const selectedStyle = {
    fontWeight: "700",
    textDecoration: "underline",
    textUnderlineOffset: "3px",
};
const notSelectedStyle = { fontWeight: "400" };

export function AppBarCustom({ menu, onNewMenu }: any) {
    const { logout, jwtPayload } = React.useContext(AuthContext);
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Button
                        color="inherit"
                        onClick={() => onNewMenu("country")}
                        style={menu === "country" ? selectedStyle : notSelectedStyle}
                    >
                        By country stats
                    </Button>
                    <Button
                        color="inherit"
                        onClick={() => onNewMenu("touchpoint")}
                        style={menu === "touchpoint" ? selectedStyle : notSelectedStyle}
                    >
                        By touchpoint stats
                    </Button>
                    <Button
                        color="inherit"
                        onClick={() => onNewMenu("mood")}
                        style={menu === "mood" ? selectedStyle : notSelectedStyle}
                    >
                        By mood stats
                    </Button>
                    <Box sx={{ flexGrow: 1 }}>
                        <Button
                            color="inherit"
                            onClick={() => onNewMenu("follow-up")}
                            style={
                                menu === "follow-up" ? selectedStyle : notSelectedStyle
                            }
                        >
                            My follow up
                        </Button>
                    </Box>
                    {jwtPayload && jwtPayload.role === "Oppy" && (
                        <Button
                            color="inherit"
                            onClick={() => onNewMenu("analyze-verbatim")}
                            style={
                                menu === "analyze-verbatim"
                                    ? selectedStyle
                                    : notSelectedStyle
                            }
                        >
                            Analyze verbatim
                        </Button>
                    )}

                    {/* This button should be at far right */}

                    <Button
                        color="inherit"
                        onClick={() => {
                            logout();
                        }}
                    >
                        Exit <ExitToAppIcon />
                    </Button>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
