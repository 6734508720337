import { startOfWeek, endOfWeek, addDays, startOfMonth, endOfMonth, startOfYear, endOfYear } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DateRange } from '@mui/x-date-pickers-pro';

const shortcutsItems: PickersShortcutsItem<DateRange<Date>>[] = [
    {
        label: 'This Week',
        getValue: () => {
            const today = new Date();
            return [startOfWeek(today), endOfWeek(today)];
        },
    },
    {
        label: 'Last Week',
        getValue: () => {
            const today = new Date()
            const prevWeek = addDays(today, -7);
            return [startOfWeek(prevWeek), endOfWeek(prevWeek)];
        },
    },
    {
        label: 'Last 7 Days',
        getValue: () => {
            const today = new Date()
            return [addDays(today, -7), today];
        },
    },
    //TODO: make it generic and dynamic
    {
        label: '2022',
        getValue: () => {
        return [
            startOfYear(new Date(2022, 0, 1)),
            endOfYear(new Date(2022, 11, 31)),
        ];
        },
    },
    {
        label: '2023',
        getValue: () => {
        return [
            startOfYear(new Date(2023, 0, 1)),
            endOfYear(new Date(2023, 11, 31)),
        ];
        },
    },
    {
        label: 'Current Month',
        getValue: () => {
            const today = new Date()
            return [startOfMonth(today), endOfMonth(today)];
        },
    },
    { label: 'Reset', getValue: () => [null, null] },
];

type Props = { onChange: (value: DateRange<Date>) => void }
export function PeriodPicker({ onChange }: Props) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
                onChange={onChange}
                label="Range picker"
                slots={{ field: SingleInputDateRangeField }}
                slotProps={{
                    textField: {
                        variant: 'standard', size: 'small'
                    },
                    shortcuts: {
                        items: shortcutsItems,
                    },
                }}
            />
        </LocalizationProvider>
    );
}