import { useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import React from "react";
import TextField from "@mui/material/TextField";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Grid,
    Typography,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { getVerbatimAnalysis } from "../utils/api";
import { IVerbatimAnalysisResult } from "../types/IVerbatimAnalysisResult";
import TagGrid from "../components/TagGrid";

interface IMoodResponse {
    response: IVerbatimAnalysisResult;
    positiveTags: string[];
    negativeTags: string[];
    neutralTags: string[];
    jsonResponse: string;
}

export const AnalyzeVerbatim = () => {
    const { jwt } = React.useContext(AuthContext);

    const [SearchFieldError, setSearchFieldError] = useState<boolean>(false);
    const [expandedPanel, setExpanded] = React.useState<string[]>([`panel${0}`]);
    const [responses, setResponse] = useState<IMoodResponse[]>([] as IMoodResponse[]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleClick = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const target = e.target as typeof e.target & {
            search: { value: string };
        };
        const value = target.search.value;
        if (value === "") {
            // SEt input field state to error if trying to submit empty
            setSearchFieldError(true);
        } else if (jwt) {
            setIsLoading(true);
            getVerbatimAnalysis(jwt, value)
                .then((verbatimResponse: IVerbatimAnalysisResult) => {
                    const data = [
                        {
                            response: verbatimResponse,
                            positiveTags: verbatimResponse.tags
                                .filter((tag) => tag.split(".")[1] === "positive")
                                .map((tag) => tag.split(".")[0]),
                            negativeTags: verbatimResponse.tags
                                .filter((tag) => tag.split(".")[1] === "negative")
                                .map((tag) => tag.split(".")[0]),
                            neutralTags: verbatimResponse.tags
                                .filter((tag) => tag.split(".")[1] === "neutral")
                                .map((tag) => tag.split(".")[0]),
                            jsonResponse: JSON.stringify(verbatimResponse, null, 2),
                        },
                        ...responses,
                    ];
                    setResponse(data);
                })
                .catch((error) =>
                    setResponse([
                        {
                            response: {
                                status: "error",
                                date: "",
                                verbatim: "An error occured",
                                tags: [],
                            },
                            positiveTags: [],
                            negativeTags: [],
                            neutralTags: [],
                            jsonResponse: JSON.stringify(error, null, 2),
                        },
                        ...responses,
                    ])
                )
                .finally(() => {
                    setIsLoading(false);
                    setExpanded([`panel${0}`]);
                });
        }
    };

    const handleChangeVerbatim = (event: any) => {
        if (event.target.value) {
            setSearchFieldError(false);
        }
    };

    const handleExpanded =
        (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded((expandedPanel) =>
                isExpanded
                    ? [...expandedPanel, panel]
                    : expandedPanel.filter((p) => p !== panel)
            );
        };

    return (
        <>
            {/* SEARCH BAR AND ANALYZE BUTTON */}
            <form onSubmit={handleClick}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4} sx={{ marginLeft: "1rem", marginTop: "1rem" }}>
                        <TextField
                            name="search"
                            label="Verbatim to analyze"
                            variant="outlined"
                            error={SearchFieldError}
                            onChange={handleChangeVerbatim}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2} sx={{ marginLeft: "1rem", marginTop: "1rem" }}>
                        <Button variant="contained" type="submit">
                            {isLoading ? (
                                <CircularProgress color="secondary" size={20} />
                            ) : (
                                <>Analyze</>
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Grid container spacing={2} alignItems="center">
                {
                    // FOR EACH RESPONSE WE CREATE AN ACCORDION
                    responses.map((response: IMoodResponse, index) => (
                        <Grid
                            item
                            key={response.response.date}
                            xs={8}
                            sx={{ marginLeft: "1rem", marginTop: "1rem" }}
                        >
                            <Accordion
                                defaultExpanded={index === 0}
                                expanded={expandedPanel.includes(`panel${index}`)}
                                onChange={handleExpanded(`panel${index}`)}
                            >
                                {/* TEXT OF THE VERBATIM + INDEX */}
                                <AccordionSummary
                                    expandIcon={<ArrowDownwardIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography
                                        sx={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "-webkit-box",
                                            WebkitLineClamp: 1,
                                            WebkitBoxOrient: "vertical",
                                        }}
                                    >
                                        Verbatim{responses.length - index}:{" "}
                                        {response.response.verbatim}
                                    </Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography
                                        fontWeight="bold"
                                        sx={{ paddingBottom: "1rem" }}
                                    >
                                        Semantic analysis:
                                    </Typography>
                                    <TagGrid tags={response.positiveTags} type="pos" />
                                    <TagGrid tags={response.negativeTags} type="neg" />
                                    <TagGrid tags={response.neutralTags} type="neu" />
                                </AccordionDetails>

                                {/* API RESPONSE */}
                                <Accordion defaultExpanded={false}>
                                    <AccordionSummary
                                        expandIcon={<ArrowDownwardIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography fontWeight="bold">
                                            API response
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            component="pre"
                                            sx={{ whiteSpace: "pre-wrap" }}
                                        >
                                            {response.jsonResponse}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Accordion>
                        </Grid>
                    ))
                }
            </Grid>
        </>
    );
};
