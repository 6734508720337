import { Grid, Typography } from "@mui/material";
import { iconsMapping } from "../utils/constants";
import React from "react";
import { fromTagPrefixToLightLabel } from "../utils/verbatimMappings";

interface IProps {
    tags: string[],
    type: 'pos' | 'neg' | 'neu'
}

const TagGrid = ({ tags, type }: IProps) => {
    return tags.length > 0 ? (
        <>
            <Grid container spacing={1} justifyContent="start" flexDirection="column">
                {
                    tags.map((tag: string, indexTag: number) => (
                        <>
                            <Grid item key={`${indexTag}-${type}`}>
                                <Grid container spacing={0} justifyContent="start" sx={{ marginBottom: "0.5rem" }}>
                                    {
                                        tag && (
                                            iconsMapping.hasOwnProperty(`${tag}`) ?
                                                React.createElement(iconsMapping[`${tag}` as keyof typeof iconsMapping], { color: "primary" })
                                                : iconsMapping.hasOwnProperty(`${tag}${type}`) ?
                                                    React.createElement(iconsMapping[`${tag}${type}` as keyof typeof iconsMapping], { color: "primary" })
                                                    : null
                                        )
                                    }
                                    <Typography sx={{ paddingLeft: '0.25rem' }} component="pre" variant="body1" >
                                        {fromTagPrefixToLightLabel(tag)} ({type})
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    ))
                }
            </Grid >
        </>
    ) : null;
};

export default TagGrid;