import { getEnvironmentVariable } from "./tools";

const buildUrl = (aSuffixStartingWithSlash: string) => {
    return getEnvironmentVariable("REACT_APP_API_URL") + aSuffixStartingWithSlash;
};

//TODO: prefer axios over fetch? Good enough for now.
//TODO: use a library to abstract the fetch + buildUrl + json parsing parts
export const loginAndGetJwtToken = async (
    login: string,
    password: string,
    mfaCode: string
) => {
    try {
        const res = await fetch(buildUrl("/user/login"), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ login, password, mfaCode }),
        });
        const result = await res.json();
        if (!result || !result.payload || result.error) {
            return null;
        }
        return result.payload as string;
    } catch (e) {
        console.log(e);
        return null;
    }
};

export const getVerbatimAnalysis = async (jwt: string, verbatim: string) => {
    const response = await fetch(buildUrl("/verbatim/analyze"), {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({ verbatim: verbatim }),
    });
    const data = await response.json();
    return data;
};

export const getJwtMongoCharts = async (jwt: string) => {
    const response = await fetch(buildUrl("/analysis/getJwtMongoCharts"), {
        headers: {
            Authorization: `Bearer ${jwt}`,
        },
    });
    const data = await response.json();
    return data.payload;
};

export const getAllDatas = async (jwt: string) => {
    const response = await fetch(buildUrl("/analysis/getAllDatas"), {
        headers: {
            Authorization: `Bearer ${jwt}`,
        },
    });
    const data = await response.json();
    return data.payload;
};

export const patchAnswer = async (
    jwt: string,
    anId: string,
    insatReason: string,
    insatTreatment: string,
    explanations: string
) => {
    const res = await fetch(buildUrl(`/response/${anId}`), {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({
            insatReason,
            insatTreatment,
            explanations,
        }),
    });
    const result = await res.json();
    return result;
};
