import { createContext } from "react";
import { XylemReceivedData } from "../types/types";

export const mapARowToCustomXylemAnalysisWithAnswer = (x: XylemReceivedData) => {
    let country = "FR";
    if (x.integration.XylemCountry.match(/uk/i)) {
        country = "UK";
    }
    if (x.integration.XylemCountry.match(/de/i)) {
        country = "DE";
    }
    if (x.integration.XylemCountry.match(/it/i)) {
        country = "IT";
    }
    if (x.integration.XylemCountry.match(/es/i)) {
        country = "ES";
    }
    if (x.integration.XylemCountry.match(/pt/i)) {
        country = "PT";
    }
    //TODO: normally x.extendedResponse is always defined because we filtered right before!
    return {
        id: x._id,
        parsedDate: x.date.slice(0, 10),
        contactKey: x.integration.ContactKey,
        country: country,
        customerCompanyName: x.integration.CustomerCompanyName,
        customerCompanyGroupName: x.integration.CustomerCompanyGroupName,
        shouldContactBecauseOfInsatisfaction:
            x.answersCalculated.generalRating < 5 &&
            x.answers.shouldContactBecauseOfInsatisfaction === "yes"
                ? "yes"
                : "no",
        contacted: Boolean((x.extendedResponse?.agentAnalysis as any)?.insatReason)
            ? "yes"
            : "no",
        ...x,
    };
};

export type CustomXylemAnalysisWithAnswer = ReturnType<
    typeof mapARowToCustomXylemAnalysisWithAnswer
>;

//xylemResponse has the form of the return type of mapARowToCustomXylemAnalysisWithAnswer
export const DataContext = createContext({
    xylemResponses: [] as CustomXylemAnalysisWithAnswer[],
    refreshXylemResponses: async () => {},
    allCompanyNames: [] as string[],
    allCompanyGroupNames: [] as string[],
});
