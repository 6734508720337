export const tagsMapping = {
    /** Tags related to Verbatim Analysis API */
    "promotions.neutral": "Promotions",
    "price.positive": "Price (pos)",
    "price.negative": "Price (neg)",
    "price.neutral": "Price (neu)",
    "value-for-money.positive": "Value for money (pos)",
    "value-for-money.negative": "Value for money (neg)",
    "price-increase.negative": "Price increase (neg)",
    "return-allowance.negative": "Return allowance (neg)",

    "choice.positive": "Choice (pos)",
    "choice.negative": "Choice (neg)",
    "availability-stock.positive": "Availabity / Stock (pos)",
    "availability-stock.negative": "Availability / Stock (neg)",
    "guarantee.neutral": "Guarantee",
    "missing-lost-product.negative": "Missing / Lost product (neg)",
    "damaged-product.negative": "Damaged product (neg)",
    "reliability.positive": "Reliability (pos)",
    "reliability.negative": "Reliability (neg)",
    "quality.positive": "Quality (pos)",
    "quality.negative": "Quality (neg)",

    "lead-time.positive": "Lead time (pos)",
    "lead-time.negative": "Lead time (neg)",
    "lead-time.neutral": "Lead time (neu)",
    "signature.neutral": "Signature (neu)",
    "quality-of-service.positive": "Quality of service (pos)",
    "quality-of-service.negative": "Quality of service (neg)",
    "follow-up.positive": "Follow-up (pos)",
    "follow-up.negative": "Follow-up (neg)",
    "refusal.negative": "Refusal (neg)",
    "commercial-trade-off.neutral": "Commercial trade off",
    "reachability.positive": "Reachability (pos)",
    "reachability.negative": "Reachability (neg)",

    "welcome-kindness.positive": "Welcome / Kindness (pos)",
    "welcome-kindness.negative": "Welcome / Kindness (neg)",
    "attentiveness-and-availability.positive": "Attentiveness and availability (pos)",
    "attentiveness-and-availability.negative": "Attentiveness and availability (neg)",
    "skills.positive": "Skills (pos)",
    "skills.negative": "Skills (neg)",
    "efficiency-reactivity.positive": "Efficiency / Reactivity (pos)",
    "efficiency-reactivity.negative": "Efficiency / Reactivity / Reminder (neg)",

    "feeling.positive": "Feeling (pos)",
    "feeling.negative": "Feeling (neg)",
    "feeling.neutral": "No comment (neu)",

    /** Tags related to Viavoo API
     * Data prior to 2024 were tagged with those so we need to keep them for compatibility
     */
    "promo.all": "Promotions",
    "prix.pos": "Price (pos)",
    "prix.neg": "Price (neg)",
    "prix.autre": "Price (neu)",
    "rapp.q.p.pos": "Value for money (pos)",
    "rapp.q.p.neg": "Value for money (neg)",
    "prix.var.neg": "Price increase (neg)",
    "abattement.neg": "Return allowance (neg)",

    "choix.pos": "Choice (pos)",
    "choix.neg": "Choice (neg)",
    "dispo.pos": "Availabity / Stock (pos)",
    "dispo.neg": "Availability / Stock (neg)",
    "garantie.all": "Guarantee",
    "manque.produit.neg": "Missing / Lost product (neg)",
    "abime.neg": "Damaged product (neg)",
    "fiabilite.pos": "Reliability (pos)",
    "fiabilite.neg": "Reliability (neg)",
    "qualite.pos": "Quality (pos)",
    "qualite.neg": "Quality (neg)",

    "delai.pos": "Lead time (pos)",
    "delai.neg": "Lead time (neg)",
    "delai.autre": "Lead time (neu)",
    "signature.all": "Signature (neu)",
    "service.pos": "Quality of service / Follow-up (pos)",
    "service.neg": "Quality of service / Follow-up (neg)",
    "refus.all": "Refusal (neg)",
    "geste.co.all": "Commercial trade off",
    "joignabilite.pos": "Reachability (pos)",
    "joignabilite.neg": "Reachability (neg)",

    "accueil.pos": "Welcome / Kindness (pos)",
    "accueil.neg": "Welcome / Kindness (neg)",
    "ecoute.pos": "Attentiveness and availability (pos)",
    "ecoute.neg": "Attentiveness and availability (neg)",
    "competence.pos": "Skills (pos)",
    "competence.neg": "Skills (neg)",
    "efficacite.pos": "Efficiency / Reactivity (pos)",
    "efficacite.neg": "Efficiency / Reactivity / Reminder (neg)",

    "ressenti.pos": "Feeling (pos)",
    "ressenti.neg": "Feeling (neg)",
    "ressenti.ras": "No comment (neu)",
} as Record<string, string>;

export const fromTagToLabel = (tag: string) => {
    return tagsMapping[tag] as unknown as string;
};

export const fromTagPrefixToLightLabel = (tag: string) => {
    console.log();
    return (
        Object.keys(tagsMapping)
            .filter((k) => k.startsWith(tag))
            .map((k) => tagsMapping[k].replaceAll(/\(.+?\)/g, "").trim())[0] ||
        "Unknown"
    );
};
