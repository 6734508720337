import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export function MultipleSelectChip({
  onNewValues,
  values,
  options,
  label,
  placeholder,
  id,
}: {
  onNewValues: Function;
  values: string[];
  options: string[];
  label: string;
  placeholder: string;
  id: string;
}) {
  return (
    <Autocomplete
      multiple
      id={id || 'tags-standard'}
      options={options}
      getOptionLabel={(option) => option}
      value={values as any}
      onChange={(e, newTouchpoints) => {
        onNewValues(newTouchpoints);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
}
