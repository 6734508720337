import {
    Box,
    TextField,
    Typography,
    Button,
    CircularProgress,
    Grid,
} from "@mui/material";
import { useState, useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { loginAndGetJwtToken } from "../utils/api";
import { getAppVersion } from "../utils/tools";

export function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [mfaCode, setMfaCode] = useState("");
    const [loading, setLoading] = useState(false);
    const { jwt, login } = useContext(AuthContext);
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    if (jwt) {
        return <Navigate to="/country" />;
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setLoading(true);
        try {
            const newJwt = await loginAndGetJwtToken(username, password, mfaCode);
            if (!newJwt) {
                throw new Error("login error");
            }
            login(newJwt);
            enqueueSnackbar("Login successful!", { variant: "success" });

            navigate("/country");
        } catch (error) {
            enqueueSnackbar("Login failed, please check your data", {
                variant: "error",
            });
        }
        setLoading(false);
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                bgcolor: "background.default",
            }}
        >
            <Typography variant="h4" component="h1" gutterBottom>
                Xylem Dashboards by Omni
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Login"
                    variant="outlined"
                    margin="normal"
                    autoComplete="username"
                    required
                    fullWidth
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    autoComplete="current-password"
                    required
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                    label="MFA Code"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    value={mfaCode}
                    onChange={(e) => setMfaCode(e.target.value)}
                />
                <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"start"}
                    direction={"row"}
                    marginBottom={2}
                    marginTop={2}
                >
                    <Box>
                        <Button
                            sx={{ m: 0 }}
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Login
                        </Button>
                    </Box>
                    {loading && (
                        <Box marginLeft={2}>
                            <CircularProgress size={30} />
                        </Box>
                    )}
                </Grid>
                <div style={{ position: "fixed", bottom: "4px", right: "4px" }}>
                    {getAppVersion()}
                </div>
            </form>
        </Box>
    );
}
