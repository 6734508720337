import React, { useState, useEffect, useCallback, useMemo } from "react";
import { SnackbarProvider } from "notistack";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
    createBrowserRouter,
    RouterProvider,
    Navigate,
    useNavigate,
    useLocation,
} from "react-router-dom";

import { DashboardByCountry } from "./pages/DashboardByCountry";
import { DashboardByTouchpoint } from "./pages/DashboardByTouchpoint";
import { AppBarCustom } from "./AppBar";
import { DashboardByMood } from "./pages/DashboardByMood";
import { MyFollowUp } from "./pages/MyFollowUp";
import { Login } from "./pages/Login";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {
    CustomXylemAnalysisWithAnswer,
    DataContext,
    mapARowToCustomXylemAnalysisWithAnswer,
} from "./contexts/DataContext";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AuthContext } from "./contexts/AuthContext";
import { JWT_TOKEN_LOCAL_STORAGE_KEY, XYLEM_BLUE } from "./utils/constants";
import { getAllDatas } from "./utils/api";
import { uniq } from "lodash";
import { AnalyzeVerbatim } from "./pages/AnalyzeVerbatim";
import { XylemReceivedData } from "./types/types";

const theme = createTheme({
    palette: {
        primary: {
            main: XYLEM_BLUE,
        },
        success: {
            main: "#1EA67D",
        },
        error: {
            main: "#F27350",
        },
    },
});

const healthCheckPagePath = "health-check";

const WrapComponentWithAppBar = ({ children }: any) => {
    const location = useLocation();

    const { jwt, logout } = React.useContext(AuthContext);

    const navigate = useNavigate();

    const [xylemResponses, setXylemResponses] = useState(
        [] as CustomXylemAnalysisWithAnswer[]
    );
    const allCompanyNames = useMemo(() => {
        return uniq(
            xylemResponses.map((x) => x.integration.CustomerCompanyName).filter(Boolean)
        );
    }, [xylemResponses]);
    const allCompanyGroupNames = useMemo(() => {
        return uniq(
            xylemResponses
                .map((x) => x.integration.CustomerCompanyGroupName)
                .filter(Boolean)
        );
    }, [xylemResponses]);

    const refreshXylemResponses = useCallback(async () => {
        if (!jwt) return;

        let allDatas: any[] = [];

        try {
            allDatas = await getAllDatas(jwt);
            if (!allDatas) throw new Error("No data");
        } catch (e) {
            //In case of any errors we logout the user
            logout();
            return;
        }
        const mappedAndFilteredDatas = allDatas
            .filter((x: XylemReceivedData) => x.extendedResponse)
            .map((x: XylemReceivedData) => mapARowToCustomXylemAnalysisWithAnswer(x));
        setXylemResponses(mappedAndFilteredDatas);
    }, [setXylemResponses, jwt, logout]);

    useEffect(() => {
        refreshXylemResponses();
    }, [refreshXylemResponses]);

    if (!jwt && location.pathname !== healthCheckPagePath) {
        return <Navigate to="/login" />;
    }

    return (
        <ThemeProvider theme={theme}>
            <DataContext.Provider
                value={{
                    xylemResponses: xylemResponses,
                    refreshXylemResponses,
                    allCompanyNames,
                    allCompanyGroupNames,
                }}
            >
                <AppBarCustom
                    menu={location.pathname.replace(/^\//, "")}
                    onNewMenu={(newMenu: any) => {
                        navigate("/" + newMenu);
                    }}
                />
                {children}
            </DataContext.Provider>
        </ThemeProvider>
    );
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <Navigate replace to="/country" />,
    },
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/country",
        element: (
            <WrapComponentWithAppBar>
                <DashboardByCountry />
            </WrapComponentWithAppBar>
        ),
    },
    {
        path: "/touchpoint",
        element: (
            <WrapComponentWithAppBar>
                <DashboardByTouchpoint />
            </WrapComponentWithAppBar>
        ),
    },
    {
        path: "/mood",
        element: (
            <WrapComponentWithAppBar>
                <DashboardByMood />
            </WrapComponentWithAppBar>
        ),
    },
    {
        path: "/follow-up",
        element: (
            <WrapComponentWithAppBar>
                <MyFollowUp />
            </WrapComponentWithAppBar>
        ),
    },
    {
        path: "/analyze-verbatim",
        element: (
            <WrapComponentWithAppBar>
                <AnalyzeVerbatim />
            </WrapComponentWithAppBar>
        ),
    },
    {
        path: healthCheckPagePath,
        element: <>OK</>,
    },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

//TODO: we should type properly
const jwtInLocalStorage = localStorage.getItem(JWT_TOKEN_LOCAL_STORAGE_KEY) || null;

const AuthWrapper = ({ children }: any) => {
    const [jwt, setJwt] = useState(jwtInLocalStorage);
    const jwtPayload = useMemo(() => {
        if (!jwt) {
            return null;
        }

        //It is not the role here to "logout" if we can't parse properly the jwt. We let it to the catch of some of the main API calls (when returning 401).
        try {
            //TODO: it would be better to use verifyJwtP256SignatureAndGetPayloadOrNull but it is async and it makes thing less readable.
            return JSON.parse(atob(jwt.split(".")[1]));
        } catch (e) {
            return null;
        }
    }, [jwt]);

    const login = useCallback(
        (newJwt: string) => {
            localStorage.setItem(JWT_TOKEN_LOCAL_STORAGE_KEY, newJwt);
            setJwt(newJwt);
        },
        [setJwt]
    );
    const logout = useCallback(() => {
        localStorage.removeItem(JWT_TOKEN_LOCAL_STORAGE_KEY);
        setJwt(null);
    }, [setJwt]);

    return (
        <AuthContext.Provider
            value={{
                jwt,
                jwtPayload,
                login,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

root.render(
    <React.StrictMode>
        <SnackbarProvider>
            <AuthWrapper>
                <RouterProvider router={router} />
            </AuthWrapper>
        </SnackbarProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
